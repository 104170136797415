/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Post } from '@kalkuz/fetch-http';

function Home() {
  const [date, setDate] = useState(dayjs());
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(dayjs());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen overflow-y-auto">
      <div id="navbar" className="w-full h-16 p-2 flex">
        <div className="flex pl-3 items-center gap-1 md:gap-2 cursor-pointer select-none">
          <img
            className="w-10 h-10 p-0.5"
            src="logo.png"
            alt="Kalkuz Meet"
          />
          <p className="font-semibold text-gray-500 md:text-2xl">Kalkuz</p>
          <p className="font-light text-gray-500 md:text-2xl">Meet</p>
        </div>
        <div className="flex justify-end items-center gap-2 ml-auto text-gray-500 pr-4 text-xs md:text-base">
          <p>{date.format('HH:mm')}</p>
          <p> • </p>
          <p>{date.format('DD MMMM ddd')}</p>
        </div>
      </div>

      <div className="w-full h-full flex">
        <div className="w-full md:w-2/5 h-5/6 md:h-full flex flex-col justify-center items-center">
          <div className="w-full md:w-4/6 p-12">
            <p className="text-3xl font-light md:text-5xl">
              Premium görüntülü toplantılar. Artık herkes için ücretsiz.
            </p>
            <p className="mt-4 md:mt-8 text-gray-500 text-sm md:text-base">
              Güvenli iş toplantıları için geliştirilen Kalkuz Meet yeniden tasarlandı.
              Hizmete artık herkes ücretsiz olarak ulaşabilir.
            </p>

            <div className="mt-8 flex gap-4 md:gap-8 flex-col md:flex-row">
              <button
                type="button"
                className="w-full md:w-1/3 h-12 bg-blue-500 duration-300 hover:bg-blue-600 text-white rounded-md shadow-md shadow-[#0003] font-semibold"
                onClick={() => {
                  Post('/rooms', {}).then((res) => {
                    navigate(`/${res.roomId}`);
                  }).catch((err) => {
                    // console.error('Error occured while creating room', err);
                  });
                }}
              >
                Yeni Toplantı
              </button>
              <button
                type="button"
                className="w-full md:w-2/3 h-12 bg-gray-200 duration-300 hover:bg-gray-300 text-gray-500 rounded-md shadow-md shadow-[#0003]"
              >
                Toplantıya Katıl
              </button>
            </div>

            <hr className="mt-8 h-0.5 bg-gray-200" />

            <div className="mt-4 md:mt-8 flex gap-1 text-xs md:text-base">
              <p className="text-gray-500">Kalkuz Meet hakkında</p>
              <a
                href="www.google.com"
                className="text-blue-500 hover:underline"
              >
                daha fazla bilgi
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Home);
