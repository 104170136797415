import React, { useEffect } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import FetchHttp from '@kalkuz/fetch-http';

import Home from './pages/Home';
import Meeting from './pages/Meeting';

const authToken = process.env.REACT_APP_API_TOKEN;

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/:meetingid',
    element: <Meeting token={authToken} />,
  },
]);

function App() {
  useEffect(() => {
    FetchHttp.Configurate('https://api.videosdk.live/v2', () => authToken);
  }, []);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
