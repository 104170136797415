import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import { useMeeting } from '@videosdk.live/react-sdk';
import dayjs from 'dayjs';
import {
  MicrophoneIcon,
  VideoCameraIcon,
  ArrowPathIcon,
  PhoneXMarkIcon,
  XMarkIcon,
  DocumentDuplicateIcon,
  CheckIcon,
} from '@heroicons/react/24/solid';
import ReactLoading from 'react-loading';
import ParticipantGrid from './ParticipantGrid';
import LocalParticipantView from './LocalParticipantView';

function MeetingView({ meetingId, onMeetingLeave }) {
  const initialized = useRef(false);
  const [date, setDate] = useState(dayjs());
  const [invitePanelOpen, setInvitePanelOpen] = useState(true);
  const [showCheckOnClipboard, setShowCheckOnClipboard] = useState(false);

  const [remoteParticipants, setRemoteParticipants] = useState([]);

  const [joined, setJoined] = useState(null);
  const {
    join,
    localMicOn,
    localWebcamOn,
    toggleMic,
    toggleWebcam,
    changeWebcam,
    leave,
    participants,
    localParticipant,
  } = useMeeting({
    onMeetingJoined: () => {
      setJoined('JOINED');
    },
    onMeetingLeft: () => {
      onMeetingLeave();
    },
  });

  const joinMeeting = () => {
    setJoined('JOINING');
    join();
  };

  useEffect(() => {
    const remoteParticipantsArray = [];
    participants.forEach((participant) => {
      if (!participant.local && participant.id !== localParticipant.id) {
        remoteParticipantsArray.push(participant);
      }
    });
    setRemoteParticipants(remoteParticipantsArray);
  }, [participants, localParticipant]);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production' && !initialized.current) {
      initialized.current = true;
      return () => {};
    }

    const interval = setInterval(() => {
      setDate(dayjs());
    }, 1000);

    setTimeout(() => {
      joinMeeting();
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen h-screen bg-neutral-900 overflow-y-auto">
      {joined === 'JOINED' && (
        <>
          <div className="relative w-full h-[calc(100%-5rem)] flex flex-col items-center justify-center">
            {invitePanelOpen && (
              <div className="absolute bottom-4 left-4 md:left-8 w-[calc(100vw-2rem)] md:w-[22.5rem] bg-white rounded-xl p-6 z-10">
                <button
                  type="button"
                  className="absolute top-4 right-4 w-10 aspect-square rounded-full flex justify-center items-center duration-200 text-neutral-500 hover:text-neutral-800"
                  onClick={() => setInvitePanelOpen(false)}
                >
                  <XMarkIcon className="h-6 aspect-square" />
                </button>
                <p className="md:text-lg text-neutral-700">Toplantınız hazır</p>

                <p className="font-light text-xs md:text-sm mt-4 md:mt-8">
                  Bu toplantıya katılmasını istediğiniz kişilerle aşağıdaki
                  bağlantıyı paylaşın.
                </p>

                <div className="mt-4 flex items-center gap-2 rounded-md bg-neutral-100">
                  <input
                    type="text"
                    className="w-full h-12 rounded-md px-4 text-sm focus:outline-none bg-transparent cursor-default"
                    value={`${window.location.host}/${meetingId}`}
                    readOnly
                  />
                  <button
                    type="button"
                    className="w-10 aspect-square duration-300 text-neutral-700 hover:text-neutral-900 rounded-md font-semibold"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.host}/${meetingId}`,
                      );
                      setShowCheckOnClipboard(true);
                      setTimeout(() => {
                        setShowCheckOnClipboard(false);
                      }, 3000);
                    }}
                  >
                    {showCheckOnClipboard ? (
                      <CheckIcon className="h-5 w-5" />
                    ) : (
                      <DocumentDuplicateIcon className="h-5 w-5" />
                    )}
                  </button>
                </div>

                <p className="font-light text-xs mt-4 md:mt-8">
                  Bu bağlantıyı kullanan herkes toplantınıza katılabilir.
                </p>
              </div>
            )}

            <div className="w-full h-full flex flex-col items-center justify-center px-16 pt-4">
              <ParticipantGrid participants={remoteParticipants} />
            </div>

            {localParticipant && (
              <div className="absolute bottom-4 right-4 w-60 h-32 shadow-lg">
                <LocalParticipantView participant={localParticipant} />
              </div>
            )}
          </div>

          <div className="w-full h-20 flex px-6 justify-between items-center">
            <div className="flex gap-2 text-white">
              <p>{date.format('HH:mm')}</p>
              <p> | </p>
              <p>{meetingId}</p>
            </div>

            <div className="flex gap-3 text-white">
              <button
                type="button"
                className={`${
                  localMicOn
                    ? 'bg-neutral-700 hover:bg-neutral-600'
                    : 'bg-red-600 hover:bg-red-700'
                } h-10 aspect-square rounded-full flex justify-center items-center duration-200 text-white shadow-md shadow-[#0003]`}
                onClick={() => toggleMic()}
              >
                <MicrophoneIcon className="h-5 w-5" />
              </button>

              <button
                type="button"
                className={`${
                  localWebcamOn
                    ? 'bg-neutral-700 hover:bg-neutral-600'
                    : 'bg-red-600 hover:bg-red-700'
                } h-10 aspect-square rounded-full flex justify-center items-center duration-200 text-white shadow-md shadow-[#0003]`}
                onClick={() => toggleWebcam()}
              >
                <VideoCameraIcon className="h-5 w-5" />
              </button>

              <button
                type="button"
                className="bg-neutral-700 hover:bg-neutral-600 h-10 aspect-square rounded-full flex justify-center items-center duration-200 text-white shadow-md shadow-[#0003]"
                onClick={() => changeWebcam()}
              >
                <ArrowPathIcon className="h-5 w-5" />
              </button>

              <button
                type="button"
                className="bg-red-600 hover:bg-red-700 h-10 w-16 rounded-full flex justify-center items-center duration-200 text-white shadow-md shadow-[#0003]"
                onClick={() => leave()}
              >
                <PhoneXMarkIcon className="h-5 w-5" />
              </button>
            </div>

            <div className="flex gap-2 text-white">
              <p>{date.format('DD MMMM ddd')}</p>
            </div>
          </div>
        </>
      )}

      {joined === 'JOINING' && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <ReactLoading type="spin" color="#fff" height={64} width={64} />
          <p className="mt-4 text-white font-light">Katılıyor...</p>
        </div>
      )}
    </div>
  );
}

export default memo(MeetingView);
