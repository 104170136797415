import React, { memo } from 'react';
import { MeetingProvider } from '@videosdk.live/react-sdk';
import { useNavigate, useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';

import MeetingView from './MeetingView';

function Meeting({ token }) {
  const { meetingid } = useParams();
  const navigate = useNavigate();

  return meetingid && token ? (
    <MeetingProvider
      config={{
        meetingId: meetingid,
        name: 'Kalkuz',
        micEnabled: true,
        webcamEnabled: true,
      }}
      token={token}
    >
      <MeetingView meetingId={meetingid} onMeetingLeave={() => navigate('/')} />
    </MeetingProvider>
  ) : (
    <div className="absolute w-screen h-screen flex flex-col items-center justify-center">
      <ReactLoading type="bubbles" color="#000" height="4rem" width="4rem" />
      <p className="text-gray-500 text-xl font-light">Bağlanıyor</p>
    </div>
  );
}

export default memo(Meeting);
