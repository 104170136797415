import { UserIcon } from '@heroicons/react/24/solid';
import { useParticipant } from '@videosdk.live/react-sdk';
import React, { memo, useEffect } from 'react';

function ParticipantView({ participant }) {
  const {
    webcamStream, micStream, displayName, setQuality,
  } = useParticipant(participant.id);

  useEffect(() => {
    if (setQuality) setQuality('high');
  }, [setQuality]);

  return (
    <div className="relative w-full h-full bg-neutral-700 rounded-xl overflow-hidden">
      {webcamStream && (
        <video
          className="w-full h-full object-cover"
          autoPlay
          playsInline
          ref={(el) => {
            if (el) {
              const mediaStream = new MediaStream();
              mediaStream.addTrack(webcamStream.track);
              // eslint-disable-next-line no-param-reassign
              el.srcObject = mediaStream;
            }
          }}
        >
          <track kind="captions" />
        </video>
      )}

      {micStream && (
        <audio
          autoPlay
          ref={(el) => {
            if (el) {
              const mediaStream = new MediaStream();
              mediaStream.addTrack(micStream.track);

              // eslint-disable-next-line no-param-reassign
              el.srcObject = mediaStream;
            }
          }}
        >
          <track kind="captions" />
        </audio>
      )}

      {!webcamStream && (
        <>
          <div className="w-full h-full flex items-center justify-center">
            <div className="w-40 aspect-square rounded-full bg-neutral-600 flex flex-col items-center justify-center">
              <UserIcon className="w-1/2 h-1/2 text-neutral-400" />
            </div>
          </div>
          <p className="absolute bottom-4 left-0 text-white text-sm font-medium pl-6">
            {displayName}
          </p>
        </>
      )}
    </div>
  );
}

export default memo(ParticipantView);
