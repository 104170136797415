import React, { memo } from 'react';
import ParticipantView from './ParticipantView';

function Grid1({ participant }) {
  return participant?.id && (<ParticipantView participant={participant} />);
}

function Grid2({ participants }) {
  return (
    <div className="w-full h-full grid grid-cols-2 gap-2">
      {participants.map((participant) => (
        <ParticipantView key={participant.id} participant={participant} />
      ))}
    </div>
  );
}

function ParticipantGrid({ participants }) {
  return (
    <div className="flex flex-wrap w-full h-full">
      {participants.length === 1 && (<Grid1 participant={participants[0]} />)}
      {participants.length === 2 && (<Grid2 participants={participants} />)}
    </div>
  );
}

export default memo(ParticipantGrid);
